// first palette for Cocoa Casino
$bg-color: #2e180d;
$text-color: #fae6e5;
$primary: #bc774a;
$success: #4b9840;
$info: #965d37;
$danger: #a3341a;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;
$gray-700: mix($bg-color, #fff, 80%); //bs-border-color



#rivalHeaderNavAndLogoSection img {
  margin-top: -10px;
  margin-bottom: -10px;
  width: auto !important;
  max-width: 200px !important;
  height: 60px !important;
}


#promos {
  img {
    width: 150% !important;
  }
}

.sticky-sidebar {
  //menu in primary color
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }

  .dropdown-item:active {
    background-color: $primary !important;
  }
}



#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}

